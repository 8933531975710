@import 'colors.scss';
@import 'sizes.scss';

.container.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  padding: 30px 30px 0;
}

.title {
  margin: 0 0 30px;
  font-size: 34px;
  text-align: center;
}

.appleButton.appleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $buttonSpacing;
}

.google.google {
  margin: 60px 0 $buttonSpacing;
  border: 1px solid $grey200;
}

.googleV2.googleV2 {
  margin: 60px 0 $buttonSpacing;
}

.facebook.facebook {
  display: flex;
  width: 100%;
}

.facebookNotice {
  margin: 10px auto $dividerSpacing;
  font-size: 14px;
  text-align: center;
}

.divider {
  margin-bottom: $dividerSpacing;
}

.form {
  margin-bottom: 40px;
}

.emailLogin {
  margin: 0;
  text-align: center;
}

.legal {
  margin: auto 0 18px;
  padding-top: 40px;
  font-size: 14px;
  text-align: center;
}

@each $theme, $themeColors in $themes {
  $secondaryColor: map-get($themeColors, 'secondary');
  $background: map-get($themeColors, 'lightBackground');

  .#{$theme}.title {
    color: $secondaryColor;
  }
}
