@import 'colors.scss';

.wrapper {
  background-color: $white;
}

.logoContainer {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  .logo {
    height: 20px;
    object-fit: contain;
  }
}

.gameSummary {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 20px;

  .image {
    width: 50%;
    max-width: 270px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px transparentize($black, 0.9);
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .title {
    text-align: center;
    width: 100%;
  }
}

.gameSummary.fitness {
  background-color: #effcf5;
  color: #2c676a;
}

.gameSummary.mindset {
  background-color: #fae2c8;
  color: #563d17;
}

.gameSummary.nutrition {
  background-color: #e7d6f1;
  color: #4f2b68;
}

.inviteSummary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  h2 {
    font-size: 30px;
    line-height: 36px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatar {
    display: flex;
    align-items: center;
    max-width: 90%;
    margin: 40px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;

    img {
      margin-right: 10px;
    }
  }

  .incentive {
    padding: 0 15%;
    text-align: center;
  }
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;

  .button {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: var(--color-purple-400);
    border: 1px solid var(--color-purple-400);

    // stylelint-disable-next-line
    &:hover svg path {
      fill: $white;
    }
  }
}

.expire {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #330b66;
  background-color: #ffecbd;
}
